import { trapFocus } from './global.js';
import { setCookie, getCookie } from './cookie.js';

class AgeVerifier extends HTMLElement {
  constructor() {
    super();
    this.ageVerifyDetail = this.querySelector('.age-verify-detail');
    this.declineVerifyDetail = this.querySelector('.decline-verify-detail');
    this.init();
    this.mainFunction();
    if (Shopify.designMode) {
      document.addEventListener('shopify:section:load', () => this.init());
      document.addEventListener('shopify:section:load', () => this.mainFunction());
    }
  }

  init() {
    const _this = this;
    const designMode = _this.dataset.enableDesignMode;
    if (!Shopify.designMode) {
      if (!getCookie('bls_age_verifier')) {
        setTimeout(() => {
          _this.setAttribute('open', '');
          this.declineVerifyDetail.classList.add('d-none');
          this.ageVerifyDetail.classList.remove('d-none');
          setTimeout(() => {
            trapFocus(this.ageVerifyDetail);
          });
        }, 1000);
      } else {
        if (getCookie('bls_age_verifier') == 'false') {
          setTimeout(() => {
            _this.setAttribute('open', '');
            this.declineVerifyDetail.classList.remove('d-none');
            this.ageVerifyDetail.classList.add('d-none');
            setTimeout(() => {
              trapFocus(this.declineVerifyDetail);
            });
          }, 1000);
        } else {
          _this.removeAttribute('open');
          removeTrapFocus();
        }
      }
    } else {
      if (designMode == 'true') {
        document.addEventListener('shopify:section:select', (e) => {
          var qbe = document.querySelector('.overlay-age-verifier')?.dataset.shopifyEditorSection;
          if (qbe && JSON.parse(qbe).id === e.detail.sectionId) {
            _this.setAttribute('open', '');
            this.declineVerifyDetail.classList.add('d-none');
            this.ageVerifyDetail.classList.remove('d-none');
          } else {
            _this.setAttribute('closing', 'true');
            setTimeout(() => {
              _this.removeAttribute('closing');
              _this.removeAttribute('open');
            }, 450);
          }
        });
      } else {
        _this.setAttribute('closing', 'true');
        setTimeout(() => {
          _this.removeAttribute('closing');
          _this.removeAttribute('open');
        }, 450);
      }
    }
  }

  mainFunction() {
    const approve = this.querySelector('.age-verifier-approve');
    const decline = this.querySelector('.age-verifier-decline');
    const returnBtn = this.querySelector('.age-verifier-return');
    if (returnBtn) {
      returnBtn.addEventListener('click', () => this.handleReturn());
    }

    if (!approve || !decline) return;
    approve.addEventListener('click', () => this.handleApprove());
    decline.addEventListener('click', () => this.handleDecline());
  }

  handleReturn() {
    if (!Shopify.designMode) {
      setCookie('bls_age_verifier', '-1', false);
      this.init();
    } else {
      this.setAttribute('open', '');
      this.declineVerifyDetail.classList.add('d-none');
      this.ageVerifyDetail.classList.remove('d-none');
    }
  }

  handleDecline() {
    if (!Shopify.designMode) {
      setCookie('bls_age_verifier', '365', false);
      this.init();
    } else {
      this.setAttribute('open', '');
      this.declineVerifyDetail.classList.remove('d-none');
      this.ageVerifyDetail.classList.add('d-none');
    }
  }

  handleApprove() {
    if (!Shopify.designMode) {
      setCookie('bls_age_verifier', '365', true);
      this.setAttribute('closing', 'true');
      setTimeout(() => {
        this.removeAttribute('closing');
        this.removeAttribute('open');
        if (document.querySelector(`#newsletterPopup_0_1`)) {
          trapFocus(document.querySelector(`#newsletterPopup_0_1`));
        }
      }, 450);
    } else {
      this.setAttribute('closing', 'true');
      setTimeout(() => {
        this.removeAttribute('closing');
        this.removeAttribute('open');
      }, 450);
    }
  }
}

export default AgeVerifier;
